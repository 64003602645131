<template>
  <div>
    <v-row class="match-height">
      <v-col cols="12">
        <v-card>
          <v-card-title>User Category Edit</v-card-title>
          <v-card-text>
            <v-form
              ref="form"
              lazy-validation
              class="multi-col-validation"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="item.cname"
                    :rules="[rules.required]"
                    label="Name"
                    outlined
                    dense
                    placeholder="Name"
                    hide-details
                  ></v-text-field>
                </v-col>

                <v-col
                  class="d-flex"
                  cols="12"
                  sm="6"
                >
                  <v-select
                    v-model="item.cstatus"
                    :items="statuses"
                    label="Status"
                    dense
                    outlined
                  ></v-select>
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-file-input
                    v-model="item.new_image"
                    label="Image"
                    outlined
                    dense
                    @change="setImg"
                  ></v-file-input>
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                  class="justify-center d-flex"
                >
                  <img
                    v-if="item.cimage"
                    class="preview-img"
                    :src="item.cimage"
                  />
                </v-col>

                <v-col cols="12">
                  <v-btn
                    :loading="process"
                    color="primary"
                    @click="submit"
                  >
                    Submit
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiMagnify, mdiPencilOutline } from '@mdi/js'
import { serialize } from 'object-to-formdata'

export default {
  name: 'UserCategoryEdit',
  setup() {
    return {
      icons: {
        mdiMagnify,
        mdiPencilOutline,
      },
    }
  },
  data() {
    return {
      item: {},
      statuses: [
        {
          text: 'Inactive',
          value: 0,
        },
        {
          text: 'Active',
          value: 1,
        },
        {
          text: 'Deleted',
          value: 0,
        },
      ],
      process: false,
      rules: {
        required: value => !!value || 'Required.',
      },
    }
  },
  computed: {
    init() {
      return this.$store.getters['Init/init']
    },
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      const url = `user-categories/${this.$route.params.id}`
      this.$api.get(url).then(response => {
        this.item = response.data

        if (this.item.cimage != null) {
          this.item.cimage = process.env.VUE_APP_MEDIA_URL + this.item.cimage
        }
      })
    },
    setImg() {
      if (this.item.new_image == null) {
        this.item.cimage = null
      } else {
        this.item.cimage = URL.createObjectURL(this.item.new_image)
      }
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.process = true
        this.item._method = 'PUT'
        const options = {
          indices: false,
        }
        const formData = serialize(this.item, options)
        this.$api
          .post(`user-categories/${this.item.id}`, formData, {
            'Content-Type': 'multipart/form-data',
          })
          .then(response => {
            this.$toast.info(response.message)
            this.$router.push({ name: 'UserCategoryList' })
            this.process = false
          })
          .catch(() => {
            this.process = false
          })
      }
    },
  },
}
</script>
